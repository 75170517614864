import React from 'react'
import getConfig from 'next/config'
import { Link } from '@nextui-org/react'
import Image from 'next/image'
import Logo_Rise_BK from '@/assets/img/logo/Logo_Rise_BK.svg'
import Logo_Rise_WT from '@/assets/img/logo/Logo_Rise_WT.svg'
import Logo_Atom_Agency_WT from '@/assets/img/logo/Logo_Atom_WT.svg'
import Logo_Atom_Agency_BK from '@/assets/img/logo/Logo_Atom_BK.svg'
import Logo_MRS_DEWI_WEBP from '@/assets/img/logo/LOGO_Dewi.webp'

const { publicRuntimeConfig } = getConfig()

const AtomAgencyAccounts = new Set([
  'tokyoraveagency',
  'djmamot',
  'dj_imoto',
  'vaduk.jp',
  'dj.daisuke',
  'dj_yahagi',
])

const MRS_DEWI_ACCOUNT = 'dewisukarno'

const LOGO_KEYS = {
  FANME: 'fanme_logo',
  ATOM_AGENCY: 'atom_agency',
  MRS_DEWI: 'mrs_dewi',
} as const

const agencyLogoConfig = {
  [LOGO_KEYS.FANME]: {
    isWebP: false,
    href: publicRuntimeConfig.FRONT_URL,
    light: Logo_Rise_BK,
    dark: Logo_Rise_WT,
    width: 88,
    height: 54,
  },
  [LOGO_KEYS.ATOM_AGENCY]: {
    isWebP: false,
    href: 'https://tokyorave-agency.com/',
    light: Logo_Atom_Agency_BK,
    dark: Logo_Atom_Agency_WT,
    width: 88,
    height: undefined,
  },
  [LOGO_KEYS.MRS_DEWI]: {
    isWebP: true,
    href: publicRuntimeConfig.FRONT_URL,
    light: Logo_MRS_DEWI_WEBP,
    dark: Logo_MRS_DEWI_WEBP,
    width: 88,
    height: undefined,
  },
} as const

const agencyLogos: Record<string, (isDark: boolean) => JSX.Element> = Object.fromEntries(
  Object.entries(agencyLogoConfig).map(([key, { isWebP, href, light, dark, width, height }]) => [
    key,
    (isDark: boolean) =>
      isWebP ? (
        isDark ? (
          <Image src={dark} alt={key} width={width} height={height} />
        ) : (
          <Image src={light} alt={key} width={width} height={height} />
        )
      ) : (
        <Link href={href} target="_blank" rel="noopener noreferrer">
          {isDark
            ? React.createElement(dark, { width, height })
            : React.createElement(light, { width, height })}
        </Link>
      ),
  ]),
)

const getAgencyOrFanmeLogo = (account_identity: string, isDark: boolean): JSX.Element => {
  if (AtomAgencyAccounts.has(account_identity)) {
    return agencyLogos[LOGO_KEYS.ATOM_AGENCY](isDark)
  } else if (MRS_DEWI_ACCOUNT === account_identity) {
    return agencyLogos[LOGO_KEYS.MRS_DEWI](isDark)
  }
  return agencyLogos[LOGO_KEYS.FANME](isDark)
}

export default getAgencyOrFanmeLogo
