import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { NextPageContext } from 'next'
import getConfig from 'next/config'
import { useRouter } from 'next/router'

import axios, { AxiosError } from 'axios'

import { ContentItemInterface, CreatorData } from '@/types/Creator.type'

import { useAuth, useCurrentUser } from '@/contexts/AuthContext'
import { useFollowerContext } from '@/contexts/FollowerContext'

import FanmeLoading from '@/components/atoms/FanmeLoading'
import CreatorContents from '@/components/molecules/CreatorCover/Contents'
import AppHead from '@/components/templates/AppHead'
import ErrorPage from '@/pages/_error'
import useCurrentCreatorState from '@/libs/useCurrentCreatorState'

import { getFollowStatus, getUnreadMsgs } from '@/pages/api/payment'
import { useCreator, useFanmeBackendGet, useUser } from '@/libs/fanme_backend'
import ThemeProvider from '@/contexts/ThemeContext'
import { event } from '@/pages/api/gtag'
import { generateSnackImageUrl } from '@/libs/utils'
import GlobalNavigation, { GlobalNavigationIcon } from '@/components/layouts/GlobalNavigation'
import { GlobalNavigationProvider } from '@/contexts/GlobalNavigationContext'

type Props = {
  creatorData: CreatorData | any
}

type CreatorsList = {
  [id: string]: string
}

const { publicRuntimeConfig } = getConfig()

const CreatorCover = ({ creatorData: initialCreatorData }: Props) => {
  const { asPath, query, isReady } = useRouter()
  const { creator_id } = query
  const creatorId = typeof creator_id === 'string' ? creator_id : null
  const { token, fetched } = useAuth()
  const { currentUser } = useCurrentUser()
  const userData = currentUser
  const followerCtx = useFollowerContext()
  const { setFollow, isFollowOn } = followerCtx

  const [isError, setIsError] = useState(false)
  const [fanLetterInfo, setFanLetterInfo] = useState<{ isOn: boolean; count: string }>({
    isOn: false,
    count: '0',
  })

  useLayoutEffect(() => {
    if (!isReady) return
    if (currentUser) {
      getUnreadMsgs()
        .then(res => {
          const count = res.unread_letter_count > 99 ? '99+' : res.unread_letter_count.toString()
          setFanLetterInfo({
            isOn: res.has_dfl,
            count,
          })
        })
        .catch(e => {
          console.error(e)
        })
    }
    if (isFollowOn) {
      const currentUid = initialCreatorData.uid

      if (!currentUser) {
        setFollow(false)
        return
      }
      getFollowStatus([currentUid]).then(res => {
        if (res.status === 200) {
          setFollow(res.data[0].following)
        } else {
          setFollow(false)
        }
      })
    }
  }, [currentUser])

  const { data: lastCreatorData, mutate: mutateCreatorData } = useCreator(query.creator_id)
  const { data: lastSelfData } = useUser()

  const { data: lastCreatorContentBlocks } = useFanmeBackendGet<any>(
    `/fanme/api/creators/${query.creator_id}/content_blocks`,
  )
  const { data: lastCreatorPopupData } = useFanmeBackendGet<any>(
    `/fanme/api/creators/${query.creator_id}/creator_popup`,
  )

  useEffect(() => {
    mutateCreatorData()
  }, [lastSelfData])

  const creatorData: any = useMemo(() => {
    const newBlocks = (
      lastCreatorContentBlocks?.content_blocks ||
      initialCreatorData?.content_blocks ||
      []
    ).map((blocks: ContentItemInterface) => {
      if (!blocks?.content_block_details) return []
      blocks.content_block_details.forEach(detail => {
        detail.icon = detail.icon.indexOf('content_block_icon_none') !== -1 ? '' : detail.icon
      })
      return blocks
    })

    return {
      ...(lastCreatorData || initialCreatorData),
      favicon: initialCreatorData?.favicon,
      ogp_image: initialCreatorData?.ogp_image,
      content_blocks: newBlocks || [],
      creator_popup: lastCreatorPopupData || initialCreatorData?.creator_popup || {},
    }
  }, [lastCreatorData, lastCreatorContentBlocks, lastCreatorPopupData, initialCreatorData])

  const userPopUpData = creatorData.creator_popup

  const visitState = useCurrentCreatorState(
    query.creator_id && fetched && userPopUpData?.updated_at
      ? `visit:${(query.creator_id as string).substring(1)}.${new Date(
          userPopUpData?.updated_at,
        ).getTime()}`
      : null,
    !token,
  )

  useEffect(() => {
    if (userData && creatorData) {
      if (userData?.account_identity === creatorData?.account_identity || creatorData?.is_public) {
        setIsError(false)

        return
      } else {
        setIsError(true)
      }
    }
  }, [userData, creatorData])

  useEffect(() => {
    if (creatorData) {
      event({
        action: 'creator_page_view',
        category: 'page_view',
        label: 'page_view',
        value: creatorData.uid,
      })
    }
  }, [])

  const creatorPopupEnabled = () => {
    return userPopUpData?.enable && creatorData && !visitState.none()
  }

  const shouldShowCreatorPopup = () => {
    if (!creatorPopupEnabled()) {
      // popupがなければ表示しない
      return false
    }
    if (creatorData?.account_identity == userData?.account_identity) {
      // 自分は表示しない
      return false
    }
    // 最後の既読時間(milliseconds)
    const v = parseInt(visitState.value() || '') || -1
    const read = Math.floor(v - new Date().getTime())

    return v < 0 || read > 12 * 3600 * 1000
  }

  if (isError) return <ErrorPage statusCode={404} />

  return (
    <>
      {!creatorData && <FanmeLoading />}
      <AppHead
        creatorName={creatorData?.name}
        pageTitle={`${creatorData?.name} - ${creatorData?.account_identity} | FANME（ファンミー）`}
        title={`${creatorData?.name}のFANME`}
        description={`今すぐ${creatorData?.name}のFANMEページをチェックしにいこう！
        FANMEは、クリエイターとファンがつながるクリエイター活動まとめサービスです。SNS、動画、商品の宣伝などをスマホだけで簡単に設置でき、すぐに自分のページを作成できます。`}
        headerImage={creatorData?.ogp_image}
        url={`${publicRuntimeConfig.FRONT_URL}${asPath}`}
        creatorId={creatorData?.account_identity}
        creatorIcon={creatorData?.favicon}
      />
      <ThemeProvider
        key={`creator-${creatorData.uid}-${creatorData?.profile?.theme_color}`}
        fixedTheme={creatorData?.profile?.theme_color}
      >
        <GlobalNavigationProvider>
          <CreatorContents
            onCreatorPopupHide={() => {
              if (userPopUpData?.updated_at) {
                const v = new Date(userPopUpData.updated_at).getTime()
                visitState.update(v)
              }
            }}
            showCreatorPopup={shouldShowCreatorPopup()}
            enableCreatorPopup={creatorPopupEnabled()}
            data={creatorData}
            loginUserData={lastSelfData}
            creatorPopUpData={userPopUpData}
            fanLetter={fanLetterInfo}
          />
          {creatorId && (
            <GlobalNavigation activeIcon={GlobalNavigationIcon.HOME} creatorId={creatorId} />
          )}
        </GlobalNavigationProvider>
      </ThemeProvider>
    </>
  )
}

export const getServerSideProps = async (ctx: NextPageContext) => {
  const creatorId = String(ctx.query.creator_id)
  const creatorsList: CreatorsList = JSON.parse(JSON.stringify(process.env.REDIRECT_CREATORS_URL))
  const targetId = Object.keys(creatorsList).filter(id => creatorId === id)
  if (targetId.length > 0) {
    return {
      redirect: {
        permanent: false,
        destination: creatorsList[targetId[0]],
      },
    }
  }

  /**
   * metaタグ生成用にサーバーサイドで取得する
   */
  const url = `${publicRuntimeConfig.API_BASE_URL}/creators/@${creatorId.replace(/^@/, '')}`
  try {
    const requests = [
      axios.get(url),
      axios.get(`${url}/content_blocks`),
      axios.get(`${url}/creator_popup`),
    ]
    const [{ data: creatorData }, { data: contentBlocks }, { data: creatorPopup }] =
      await Promise.all(requests)

    // @ で始まらない creatorId が指定されると creator api から invalid_request_param として扱われ
    // not_found の分岐に入らないため @${creatorId} にリダイレクト
    if (!creatorId.startsWith('@')) {
      return {
        redirect: {
          permanent: true,
          destination: `/@${creatorId}`,
        },
      }
    }

    const newBlocks = contentBlocks.content_blocks.map((blocks: ContentItemInterface) => {
      if (!blocks?.content_block_details) return []
      blocks.content_block_details.forEach(detail => {
        detail.icon = detail.icon.indexOf('content_block_icon_none') !== -1 ? '' : detail.icon
      })
      return blocks
    })

    creatorData.content_blocks = newBlocks
    creatorData.creator_popup = creatorPopup

    // アイコン情報
    const defaultIconUrl = `${publicRuntimeConfig.FRONT_URL}/icon.png`
    creatorData.favicon = creatorData?.icon
      ? await generateSnackImageUrl('cov(16,16)', creatorData.icon)
      : defaultIconUrl

    // OGP画像
    const ogp = await generateSnackImageUrl(
      '',
      creatorData?.profile?.header_image,
      true,
      creatorData?.name,
    )
    if (ogp) {
      creatorData.ogp_image = ogp
    } else {
      creatorData.ogp_image =
        'https://asset-fanme-link.s3.ap-northeast-1.amazonaws.com/meta/OGP2023.png'
    }

    return {
      props: {
        creatorData,
      },
    }
  } catch (e) {
    if (e instanceof AxiosError && e.response?.data) {
      console.error(e.response.data)
    } else {
      console.error(e)
    }
    return {
      notFound: true,
    }
  }
}

export default CreatorCover
