import React, { useEffect } from 'react'
import { useModal } from '@nextui-org/react'
import { useRouter, useSearchParams } from 'next/navigation'
import { event as gevent } from '@/pages/api/gtag'

import {
  StyledBadgeInnerContainer,
  StyledBadgeOuterContainer,
  StyledBadge,
} from '@/components/molecules/BlogArticleBadge/index.style'
import HalfRoundedButton from '@/components/atoms/HalfRoundedButton'
import MyMenu from '@/components/molecules/MyMenu'

import { CreatorData } from '@/types/Creator.type'
import { useFanmeBackendGet } from '@/libs/fanme_backend'
import { useHasNewNotificationStore } from '@/utils/hooks/useHasNewNotification'

interface Props {
  icon: React.ReactElement
  loginUserData: CreatorData
  officialFlag: boolean | undefined
  setIsVisibleAddPwaTutorial: React.Dispatch<React.SetStateAction<boolean>>
}

const SideMenuButton = ({
  icon,
  loginUserData,
  officialFlag,
  setIsVisibleAddPwaTutorial,
}: Props) => {
  const searchParams = useSearchParams()
  const { setVisible, visible } = useModal(!!searchParams?.get('menu'))
  const { push } = useRouter()

  const { hasNewNotification, setHasNewNotification } = useHasNewNotificationStore()
  const response = useFanmeBackendGet<any>(`/payment/internal/notification/has_new`)

  useEffect(() => {
    setHasNewNotification(response.data?.hasNewNotification ?? false)
  }, [response.data])

  const { data: badgeData } = useFanmeBackendGet<any>('/fanme/api/creators/self/article/badge', {
    auth: true,
  })

  useEffect(() => {
    const elemViewMain = document.getElementById('viewMain')
    if (elemViewMain) elemViewMain.style.display = visible ? 'none' : 'block'
    const url = new URL(window.location.href)
    if (visible) {
      url.searchParams.set('menu', '1')
    } else {
      url.searchParams.delete('menu')
    }
    push(url.toString())
  }, [visible])

  const handleClick = () => {
    gevent({
      action: 'page_menu',
      category: 'other',
    })
    setVisible(true)
    setIsVisibleAddPwaTutorial(false)
  }

  const iconWithBadge = (
    <StyledBadgeOuterContainer>
      <StyledBadgeInnerContainer right={0} top={0} style={{ zIndex: 1 }}>
        {((badgeData && badgeData.count > 0) || hasNewNotification) && <StyledBadge />}
      </StyledBadgeInnerContainer>
      {icon}
    </StyledBadgeOuterContainer>
  )

  return (
    <>
      <HalfRoundedButton size={'sm'} icon={iconWithBadge} isRight={false} onClick={handleClick} />
      <MyMenu
        officialFlag={officialFlag}
        visible={visible}
        setVisible={setVisible}
        icon={loginUserData.icon}
        name={loginUserData.name}
        accountIdentity={loginUserData.account_identity}
      />
    </>
  )
}

export default SideMenuButton
