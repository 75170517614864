import { useState, useEffect } from 'react'
import debounce from 'lodash/debounce'

/**
 * The scrollable container is not the window but a div with id 'snapParent'.
 * This hook detects whether the scroll has stopped for a certain period of time.
 * @param delay Time in milliseconds to wait after scrolling stops before showing the navigation
 * @param targetSelector CSS selector for the scrollable element (default: #snapParent)
 * @returns Boolean indicating whether the navigation should be visible
 */
export function useScrollStopDetection(delay = 1000, targetSelector = '#snapParent'): boolean {
  const [isVisible, setIsVisible] = useState<boolean>(true)

  useEffect(() => {
    if (typeof window === 'undefined') return

    let timeoutId: number

    const handleScroll = () => {
      setIsVisible(false)
      window.clearTimeout(timeoutId)

      timeoutId = window.setTimeout(() => {
        setIsVisible(true)
      }, delay)
    }

    const debouncedHandleScroll = debounce(handleScroll, 100, { leading: true })

    // Get the target element or fall back to window
    let targetElement: Window | Element = window

    if (targetSelector && typeof document !== 'undefined') {
      const element = document.querySelector(targetSelector)
      if (element) {
        targetElement = element
      }
    }

    targetElement.addEventListener('scroll', debouncedHandleScroll, { passive: true })

    return () => {
      targetElement.removeEventListener('scroll', debouncedHandleScroll)
      window.clearTimeout(timeoutId)
    }
  }, [delay, targetSelector])

  return isVisible
}
