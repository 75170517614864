import React from 'react'
import getConfig from 'next/config'
import { Link, Text } from '@nextui-org/react'
import { Colors } from '@/constants/styles/color'
import { useThemeContext } from '@/contexts/ThemeContext'
import { Styles } from '@/fanmeUi/constants/style'
import getAgencyOrFanmeLogo from '@/utils/accountAgencyChecker'

const { publicRuntimeConfig } = getConfig()

const MenuItems = [
  {
    content: '運営会社',
    link: '#',
  },
  {
    content: '利用規約',
    link: '#',
  },
  {
    content: 'プライバシーポリシー',
    link: '#',
  },
  {
    content: '特定商取引法に基づく表記',
    link: '#',
  },
  {
    content: '資金決済法に基づく表示',
    link: '#',
  },
  {
    content: 'よくある質問',
    link: '#',
  },
  {
    content: 'お問い合わせ',
    link: '#',
  },
]

interface Props {
  disableItems?: boolean
  accountIdentity: string
}

const Footer = ({ disableItems, accountIdentity }: Props) => {
  const { isDark, color: bgColor } = useThemeContext()
  const footerLogo = getAgencyOrFanmeLogo(accountIdentity, isDark)

  return (
    <>
      <div
        style={{
          marginTop: '60px',
          padding: '0',
          textAlign: 'center',
          justifyContent: 'center',
          borderTop: 0,
          background: bgColor,
          zIndex: 10,
        }}
      >
        {!disableItems && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {MenuItems.map((item, index) => {
              return (
                <Link
                  href={item.link}
                  key={index}
                  style={{
                    padding: '0 12px',
                    margin: '12px 0px 12px -1px',
                    color: Colors.GRAY,
                    fontSize: '12px',
                    borderLeft: `solid 1px ${Colors.GRAY}`,
                    borderRight: `solid 1px ${Colors.GRAY}`,
                  }}
                >
                  {item.content}
                </Link>
              )
            })}
          </div>
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {footerLogo}
        </div>
        <Link
          href={publicRuntimeConfig.FRONT_URL}
          css={{
            height: '24px',
            textAlign: 'center',
            textDecoration: 'underline',
            color: isDark ? Colors.WHITE : Colors.DARK_GRAY,
            fontFamily: 'Noto Sans JP',
            fontSize: '10px',
            letterSpacing: '0.1em',
          }}
        >
          Created by FANME
        </Link>
        <Text
          css={{
            color: isDark ? Colors.WHITE : Colors.DARK_GRAY,
            fontSize: '10px',
            lineHeight: '10px',
            marginTop: `${Styles.space * 2}px`,
            fontWeight: 400,
            fontFamily: 'Noto Sans JP',
            letterSpacing: '0.1em',
            margin: 0,
          }}
        >
          COPYRIGHT {new Date().getFullYear()} TORIHADA INC. ALL RIGHT RESERVED.
        </Text>
      </div>
    </>
  )
}

export default Footer
