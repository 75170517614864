import React, { useEffect, useState } from 'react'
import { Text } from '@nextui-org/react'
/* eslint-disable-next-line */
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

import PopUp from '@/components/atoms/PopUp'
import Notification from '@/assets/img/icons/Notification.svg'

type SwiperModalItem = {
  id: number
  image: string
  title: string
  titleSize?: 'large' | 'x-large'
  description?: string[]
}

type SwiperModalProps = {
  visible: boolean
  setVisible: (event: React.SetStateAction<boolean>) => void
  items: SwiperModalItem[]
}

const SwiperModal = ({ items, visible, setVisible }: SwiperModalProps) => {
  const closePopup = () => {
    return setVisible(false)
  }

  return (
    <div className="absolute inset-0">
      <PopUp
        popUpVisible={visible}
        setPopUpVisible={setVisible}
        onClose={closePopup}
        maxWidth="328px"
      >
        <div className="w-16 h-16 bg-WHITE p-1 -mt-8 mx-auto rounded-[32px]">
          <div className="bg-BLACK flex justify-center items-center w-14 h-14 rounded-[30px]">
            <Notification className="fill-WHITE w-7 h-7" />
          </div>
        </div>
        <Swiper
          className="fanme-swiper contents-tutorial mb-4"
          spaceBetween={40}
          slidesPerView={1}
          pagination={{
            clickable: true,
            el: '.swiper-pagination',
          }}
          modules={[Navigation, Pagination]}
          navigation={{
            nextEl: '.button_next',
          }}
          style={{ width: '100%', height: '100%' }}
        >
          {items.map((item, i) => (
            <SwiperSlide key={i}>
              <div className="px-4 h-[calc(100%-286px)] flex flex-col mb-6">
                <div className="font-medium text-fanme-black mt-5">
                  <span dangerouslySetInnerHTML={{ __html: item.title }} />
                </div>
              </div>
              <img src={item.image} className="m-[4px_auto_40px] w-[312px] h-[236px]" />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="fanme-swiper-black swiper-pagination mb-3" />
      </PopUp>
    </div>
  )
}

export default SwiperModal
