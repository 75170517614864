import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { useRouter } from 'next/router'
import getConfig from 'next/config'
import { useShopApi } from '@/libs/useShopApi'
import { useScrollStopDetection } from '@/utils/hooks/useScrollStopDetection'
import { useGlobalNavigation } from '@/contexts/GlobalNavigationContext'

import HomeFilledBlack from '@/assets/img/icons/Home_Filled_Black.svg'
import HomeGray from '@/assets/img/icons/Home_Gray.svg'
import Bag from '@/assets/img/icons/Bag.svg'
import BagGray from '@/assets/img/icons/Bag_Gray.svg'

export enum GlobalNavigationIcon {
  HOME = 'home',
  SHOP = 'shop',
}

export type GlobalNavigationIconType = 'home' | 'shop'
export type ActiveIcon = GlobalNavigationIconType | null

type GlobalNavigationProps = {
  activeIcon?: ActiveIcon
  creatorId?: string
}

const GlobalNavigation = ({ activeIcon = null, creatorId }: GlobalNavigationProps) => {
  const router = useRouter()
  const shopApi = useShopApi()
  const isVisible = useScrollStopDetection(1000, '#snapParent')
  const { publicRuntimeConfig } = getConfig()
  const { shouldShowGlobalNavigation, setShouldShowGlobalNavigation } = useGlobalNavigation()
  const [isRendered, setIsRendered] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsRendered(true)
    }, 1000)

    return () => clearTimeout(timer)
  }, [])

  useEffect(() => {
    if (!creatorId) return
    const getShopInfo = async (creatorId: string) => {
      try {
        const { data } = await shopApi.getWithoutAuth(`/shops/${creatorId.replace(/^@/, '')}/items`)

        if (data.items.length > 0) {
          // NOTE:
          // 商品が出品されている場合のみ、表示させる
          setShouldShowGlobalNavigation(true)
        } else {
          setShouldShowGlobalNavigation(false)
        }
      } catch (error) {
        setShouldShowGlobalNavigation(false)
      }
    }
    getShopInfo(creatorId)
  }, [creatorId, setShouldShowGlobalNavigation])

  if (!shouldShowGlobalNavigation) return null

  const handleTopClick = () => {
    if (creatorId) {
      router.push(`/${creatorId}`)
    }
  }

  const handleShopClick = () => {
    const fanmeShopUrl = publicRuntimeConfig.SHOP_FRONT_URL
    if (fanmeShopUrl && creatorId) {
      window.location.href = `${fanmeShopUrl}/shop/${creatorId}`
    }
  }

  const isHomeActive = activeIcon === GlobalNavigationIcon.HOME
  const isShopActive = activeIcon === GlobalNavigationIcon.SHOP

  return (
    <div
      className={clsx(
        'fixed bottom-4 left-1/2 -translate-x-1/2 transition-all duration-500 z-50',
        isRendered
          ? isVisible
            ? 'opacity-100 translate-y-0'
            : 'opacity-0 pointer-events-none'
          : 'opacity-0 translate-y-4 pointer-events-none',
      )}
    >
      <div className="w-29 h-12 flex items-center justify-center gap-6 rounded-7xl bg-WHITE border border-LIGHT_GRAY shadow-nav-float">
        <button
          onClick={handleTopClick}
          className="flex flex-col items-center"
          aria-label="ページトップへ戻る"
        >
          <div className="flex items-center justify-center">
            {isHomeActive ? (
              <HomeFilledBlack width={20} height={20} />
            ) : (
              <HomeGray width={20} height={20} />
            )}
          </div>
          <span className={clsx('mt-0.5 text-xs', isHomeActive ? 'text-BLACK' : 'text-GRAY')}>
            TOP
          </span>
        </button>
        {creatorId && (
          <button
            onClick={handleShopClick}
            className="flex flex-col items-center"
            aria-label="ショップへ移動"
          >
            <div className="flex items-center justify-center">
              {isShopActive ? <Bag width={20} height={20} /> : <BagGray width={20} height={20} />}
            </div>
            <span className={clsx('mt-0.5 text-xs', isShopActive ? 'text-BLACK' : 'text-GRAY')}>
              SHOP
            </span>
          </button>
        )}
      </div>
    </div>
  )
}

export default GlobalNavigation
